<script>
export default {
  name: 'OutramSpinner',
  data() {
    return {
      reqId: null, // Reference to the animation request id
      offset: 0, // The currrent x offset of the animation, rolls over at 2*Pi
      lastTimestamp: 0, // The timestamp of the last animation frame
    };
  },
  mounted() {
    // Request a callback for the first frame
    this.reqId = window.requestAnimationFrame(t => this.drawFrame(t));
  },
  beforeDestroy() {
    // Cancel the next animation frame before we destroy the instance
    window.cancelAnimationFrame(this.reqId);
  },
  methods: {
    drawFrame(timestamp) {
      // Get the canvas context
      const canvas = this.$refs.spinner;
      if (!canvas) return;
      const ctx = canvas.getContext('2d');

      // Grab some relevant data
      const w = canvas.width;
      const h = canvas.height;
      const f = 1; // Number of cycles per canvas

      // Calculate the new frame offset
      if (this.lastTimestamp === 0) {
        // This is the first frame, so no previous timestamp available
        this.offset = 0;
      } else {
        // Else work out how much time has passed since we last drew the frame
        const elapsedTime = timestamp - this.lastTimestamp;

        // Convert this into a sin wave frequency
        this.offset += elapsedTime / 180;

        // Cap it at 2*Pi
        if (this.offset >= 2 * Math.PI) {
          this.offset -= 2 * Math.PI;
        }
      }

      // Save this timestamp as the last timestamp
      this.lastTimestamp = timestamp;

      // Define a function for computing the y coordinate, given x and phase
      const calcY = (x, p) => (h / 2) + (h / 3) * Math.sin(
        this.offset + x * 2 * Math.PI * (f / w) + p * (2 / 3) * Math.PI,
      );

      // Clear the canvas for the next frame
      ctx.clearRect(0, 0, w, h);
      ctx.lineWidth = 3;

      // Draw first phase
      ctx.beginPath();
      for (let x = 0; x <= w; x += 1) {
        ctx.lineTo(x, calcY(x, 0));
      }
      ctx.strokeStyle = '#a0133b';
      ctx.stroke();

      // Draw second phase
      ctx.beginPath();
      for (let x = 0; x <= w; x += 1) {
        ctx.lineTo(x, calcY(x, 1));
      }
      ctx.strokeStyle = '#023443';
      ctx.stroke();

      // Draw third phase
      ctx.beginPath();
      for (let x = 0; x <= w; x += 1) {
        ctx.lineTo(x, calcY(x, 2));
      }
      ctx.strokeStyle = '#0f98c9';
      ctx.stroke();

      // Request a callback for the next animation frame
      this.reqId = requestAnimationFrame(t => this.drawFrame(t));
    },
  },
};
</script>

<template>
  <canvas ref='spinner' width='150' height='150'></canvas>
</template>
